<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="date">Datum</label>
          <input type="date" class="form-control" name="date" v-model="form.date" required />
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group">
          <label for="participants">Teilnehmer</label>
          <input
            type="number"
            min="0"
            class="form-control"
            name="participants"
            v-model.number="form.participants"
            required
          />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="studio">Studio</label>
          <select name="studio" v-model="selectedStudio" class="form-control" required>
            <option :value="null" disabled>Studio auswählen...</option>
            <option :value="studio" v-for="studio in studios" :key="studio.id">{{ studio.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-12" v-if="form.studio">
        <div class="form-group">
          <label for="course">Kurs</label>
          <select name="course" v-model="selectedCourse" class="form-control" required>
            <option :value="''" disabled>Kurs auswählen...</option>
            <option :value="course.id" v-for="course in courses" :key="course.id">
              {{ course.weekday | weekday }} {{ course.name }}
              {{ course.starts }} - {{ course.ends }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12" v-if="selectedCourse">
        <div class="form-group">
          <label for="time">Länge des Kurses in Minuten (Zu diesem Datum, zu dieser Uhrzeit)</label>
          <input v-model.number="form.time" type="number" min="0" class="form-control" required />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="trainer">Trainer</label>
          <select name="trainer" v-model="selectedTrainer" class="form-control" required>
            <option :value="''" disabled>Trainer auswählen...</option>
            <option
              :value="trainer.id"
              v-for="trainer in trainers"
              :key="trainer.id"
            >{{ trainer.displayName }}</option>
          </select>
        </div>
      </div>
      <div class="col-12" v-if="selectedTrainer">
        <div class="form-group">
          <label for="hourlyWage">Stundenlohn € (Für diesen Kurs, zu diesem Datum)</label>
          <input
            v-model.number="form.trainer.hourlyWage"
            type="number"
            min="0"
            class="form-control"
            required
          />
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">Speichern</button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { format, setHours, differenceInMinutes } from 'date-fns';

import { firestore } from '../firebase';

export default {
  data() {
    return {
      courseTimeRecord: null,
      form: {
        date: format(Date.now(), 'yyyy-MM-dd'),
        participants: 0,
        course: null,
        studio: null,
        trainer: null,
        invoiced: false
      },
      studios: [],
      courses: [],
      trainers: [],
      submitting: false,
      errMsg: '',
      selectedStudio: null,
      selectedTrainer: '',
      selectedCourse: ''
    };
  },
  async created() {
    const id = this.$route.params.id;

    const studiosSnapshot = await firestore
      .collection('studios')
      .orderBy('nr')
      .get();

    this.studios = studiosSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    const trainersSnapshot = await firestore
      .collection('trainers')
      .orderBy('displayName')
      .get();

    this.trainers = trainersSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    const trainer = this.trainers.find(
      trainer => trainer.id === this.$route.params.trainerId
    );

    this.form = {
      ...this.form,
      trainer
    };

    this.selectedTrainer = trainer.id;

    if (id) {
      const courseTimeRecordSnapshot = await firestore
        .collection('courseTimeRecords')
        .doc(id)
        .get();

      this.courseTimeRecord = {
        id: courseTimeRecordSnapshot.id,
        ...courseTimeRecordSnapshot.data()
      };
      this.form = { ...this.courseTimeRecord };
      this.selectedCourse = this.form.course.id;
      this.selectedStudio = this.form.studio;

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.courseTimeRecord) {
          await firestore
            .collection('courseTimeRecords')
            .doc(this.courseTimeRecord.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('courseTimeRecords').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'time-records'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  },
  watch: {
    async selectedStudio(studio) {
      if (studio) {
        try {
          const coursesSnapshot = await firestore
            .collection('courses')
            .where('studio', '==', studio.id)
            .orderBy('weekday')
            .orderBy('starts')
            .get();

          this.courses = coursesSnapshot.docs.map(snapshot => {
            return {
              id: snapshot.id,
              ...snapshot.data()
            };
          });
          this.form.studio = studio;
        } catch (err) {
          this.errMsg = err.message;

          throw err;
        }
      }
    },
    selectedCourse(courseId) {
      if (courseId) {
        const course = this.courses.find(course => course.id === courseId);

        if (course) {
          const [startHours, startMinutes] = course.starts.split(':');
          const [endHours, endMinutes] = course.ends.split(':');

          const startDate = setHours(Date.now(), startHours).setMinutes(
            startMinutes
          );
          const endDate = setHours(Date.now(), endHours).setMinutes(endMinutes);
          const difference = differenceInMinutes(endDate, startDate);

          this.form.time = difference;
          this.form.course = course;
        }
      }
    },
    selectedTrainer(trainerId) {
      if (trainerId) {
        const trainer = this.trainers.find(trainer => trainer.id === trainerId);

        if (trainer) {
          this.form.trainer = trainer;
        }
      }
    }
  }
};
</script>
